<template>
  <Navbar />
  <div class="admin-sell-session-wrapper">
    <div class="sell-session-container">
      <div class="sell-card">
        <h2>Vender Sesiones</h2>
        <form @submit.prevent="sellSessions" class="sell-session-form">
          <!-- RUT Input -->
          <div class="form-group">
            <label for="rut">RUT del Cliente</label>
            <input
              v-model="formattedRut"
              type="text"
              id="rut"
              @input="formatRut"
              @blur="checkUserByRut"
              placeholder="12.345.678-9"
              required
              class="input-text"
            />
          </div>

          <!-- Alerts -->
          <transition name="fade">
            <div v-if="cliente" class="alert alert-success">
              ¡Cliente encontrado! Puedes editar los datos si es necesario.
            </div>
          </transition>
          <transition name="fade">
            <div v-if="clienteNoEncontrado" class="alert alert-warning">
              Cliente no encontrado. Completa los datos manualmente.
            </div>
          </transition>

          <!-- Nombre del cliente -->
          <div class="form-group">
            <label for="nombre">Nombre del Cliente</label>
            <input
              v-model="cliente.nombre"
              type="text"
              id="nombre"
              placeholder="Nombre del cliente"
              required
              class="input-text"
            />
          </div>

          <!-- Apellido del cliente -->
          <div class="form-group">
            <label for="apellido">Apellido del Cliente</label>
            <input
              v-model="cliente.apellido"
              type="text"
              id="apellido"
              placeholder="Apellido del cliente"
              required
              class="input-text"
            />
          </div>

          <!-- Teléfono del cliente -->
          <div class="form-group">
            <label for="telefono">Teléfono del Cliente</label>
            <input
              v-model="cliente.telefono"
              type="tel"
              id="telefono"
              placeholder="Número de teléfono"
              required
              class="input-text"
            />
          </div>

          <!-- Email del cliente -->
          <div class="form-group">
            <label for="email">Email del Cliente</label>
            <input
              v-model="cliente.email"
              type="email"
              id="email"
              placeholder="Correo electrónico"
              required
              class="input-text"
            />
          </div>

          <!-- Cantidad de sesiones -->
          <div class="form-group">
            <label for="sesiones">Cantidad de Sesiones</label>
            <input
              v-model="cantidadSesiones"
              type="number"
              id="sesiones"
              @input="calcularTotal"
              placeholder="Número de sesiones"
              required
              min="1"
              class="input-text"
            />
          </div>

          <!-- Precio sugerido (editable) -->
          <div class="form-group">
            <label for="precio"
              >Precio (recomendado:
              {{ formatCurrency(precioRecomendado) }})</label
            >
            <input
              v-model="precioEditable"
              type="number"
              id="precio"
              placeholder="Precio total"
              required
              class="input-text"
            />
          </div>

          <!-- Botón para vender sesiones -->
          <div class="form-group">
            <button type="submit" class="btn-primary">Vender Sesiones</button>
          </div>
        </form>

        <!-- Mostrar mensaje si las sesiones fueron vendidas exitosamente -->
        <transition name="fade">
          <div v-if="ventaExitosa" class="alert alert-success">
            Sesiones vendidas exitosamente.
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/AdminNavbar.vue";
import apiClient from "@/api";

export default {
  name: "AdminVentaSesiones",
  components: {
    Navbar,
  },
  data() {
    return {
      formattedRut: "",
      cliente: {
        rut: "",
        nombre: "",
        apellido: "",
        telefono: "",
        email: "",
      },
      cantidadSesiones: 1,
      clienteNoEncontrado: false,
      ventaExitosa: false,
      totalPagar: 0,
      precioRecomendado: 0, // Para almacenar el precio recomendado basado en la cantidad de sesiones
      precioEditable: 0, // Campo editable para el precio que se envía al backend
    };
  },
  methods: {
    // Formatear el RUT
    formatRut() {
      let rut = this.formattedRut.replace(/\./g, "").replace(/-/g, "");
      if (rut.length > 1) {
        const cuerpo = rut.slice(0, -1);
        const verificador = rut.slice(-1);
        this.formattedRut =
          cuerpo.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + verificador;
        this.cliente.rut = rut; // Actualizar el RUT en los datos del cliente
      }
    },

    // Verificar si el cliente existe
    async checkUserByRut() {
      try {
        const response = await apiClient.get(`/auth/rut/${this.cliente.rut}`);
        if (response.data) {
          this.cliente = response.data;
          this.clienteNoEncontrado = false;
        } else {
          this.clienteNoEncontrado = true;
          this.resetClienteFields();
        }
      } catch (error) {
        this.clienteNoEncontrado = true;
        this.resetClienteFields();
        console.error("Error buscando cliente:", error);
      }
    },

    // Calcular el total a pagar basado en la cantidad de sesiones
    calcularTotal() {
      this.precioRecomendado = this.calcularPrecioSesiones(
        this.cantidadSesiones
      );
      this.precioEditable = this.precioRecomendado; // Inicialmente igual al recomendado
    },

    // Vender sesiones
    async sellSessions() {
      try {
        const response = await apiClient.post("/ventas/admin/sesiones", {
          rut: this.cliente.rut,
          nombre: this.cliente.nombre,
          apellido: this.cliente.apellido,
          telefono: this.cliente.telefono,
          email: this.cliente.email,
          cantidadSesiones: this.cantidadSesiones,
          totalPagar: this.precioEditable,
        });

        if (response.status == 200) {
          this.ventaExitosa = true;
          this.resetForm();
        } else {
          alert("Hubo un error en la venta.");
        }
      } catch (error) {
        console.error("Error al vender sesiones:", error);
        alert("Hubo un error en la venta.");
      }
    },

    // Calcular el precio sugerido
    calcularPrecioSesiones(cantidad) {
      const precios = {
        1: 8000,
        5: 35000,
        10: 60000,
        15: 75000,
      };

      if (precios[cantidad]) {
        return precios[cantidad]; // Precio exacto para 1, 5 o 10 sesiones
      }

      // Calcular precios intermedios entre 1 y 5 sesiones
      if (cantidad < 5) {
        return (precios[5] / 5) * cantidad; // $6,000 por sesión
      }

      // Calcular precios intermedios entre 5 y 10 sesiones
      if (cantidad > 5 && cantidad < 10) {
        return (precios[10] / 10) * cantidad; // $5,500 por sesión
      }

      if (cantidad > 10 && cantidad < 15) {
        return (precios[15] / 15) * cantidad; // $5,500 por sesión
      }

      // Para cantidades superiores a 10, usar el mismo precio por sesión que para 10
      return (precios[15] / 15) * cantidad; // $5,500 por sesión
    },

    // Formatear el precio como moneda
    formatCurrency(value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },

    // Resetear campos de cliente si no es encontrado
    resetClienteFields() {
      this.cliente.nombre = "";
      this.cliente.apellido = "";
      this.cliente.telefono = "";
      this.cliente.email = "";
    },

    // Resetear el formulario completo
    resetForm() {
      this.formattedRut = "";
      this.resetClienteFields();
      this.cantidadSesiones = 1;
      this.precioEditable = 0;
      this.precioRecomendado = 0;
      setTimeout(() => (this.ventaExitosa = false), 3000);
    },
  },
  mounted() {
    this.calcularTotal(); // Inicializar el precio recomendado cuando el componente se carga
  },
  watch: {
    cantidadSesiones() {
      this.calcularTotal(); // Recalcular el total al cambiar la cantidad de sesiones
    },
  },
};
</script>

<style scoped>
/* Estilos del formulario de venta de sesiones */
.admin-sell-session-wrapper {
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-top: 5em;
  background-color: #ffffff;
}

.sell-session-container {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.sell-session-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

input.input-text,
select.input-text {
  padding: 14px;
  margin-top: 8px;
  border: 1.5px solid #dcdcdc;
  border-radius: 6px;
  font-size: 1rem;
}

button.btn-primary {
  background-color: #3498db;
  color: white;
  padding: 14px 18px;
  border-radius: 8px;
  font-size: 1.1em;
  transition: background-color 0.3s ease;
}

button.btn-primary:hover {
  background-color: #2980b9;
}

.alert {
  padding: 15px;
  border-radius: 6px;
  text-align: center;
}

.alert-success {
  background-color: #e0f7e9;
  color: #28a745;
}

.alert-warning {
  background-color: #fff6cc;
  color: #8a6d3b;
}
</style>
